import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export default function Bot({ toggleShow, bot_info, call_id }) {
  const websocket = useRef();
  const audioContextRef = useRef(null);
  const audioQueueRef = useRef([]);
  const mediaRecorderRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Handle if user not connected to wifi
  const updateOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  // Handling incoming audio chunks from the server
  const handleIncoming = async (event) => {
    console.log(event);

    const base64Audio = JSON.parse(event.data)["audio"];
    console.log(base64Audio);

    if (base64Audio === null) {
      console.log("no more data returning");
      return;
    }

    // Check if the data starts with a specific string, and if so, log an error and return
    if (base64Audio.startsWith('{"me')) {
      console.error(base64Audio);
      return;
    }

    console.log(base64Audio);

    // Decode base64 into an ArrayBuffer manually
    const byteCharacters = atob(base64Audio);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the ArrayBuffer
    const audioBlob = new Blob([byteArray], { type: "audio/mp3" });

    // Create a URL for the Blob
    const audioUrl = URL.createObjectURL(audioBlob);

    // Add the audio URL to the queue
    audioQueueRef.current.push(audioUrl);

    // Play the audio if it's the only item in the queue (i.e., nothing is playing)

    console.log(audioQueueRef.current.length);

    if (audioQueueRef.current.length === 1) {
      playNextInQueue();
    }
  };

  // Playing the queued audio in order
  const playNextInQueue = () => {
    if (audioQueueRef.current.length === 0 || !audioContextRef.current) return;

    const audioUrl = audioQueueRef.current[0]; // Get the Blob URL

    // Fetch the audio data as an ArrayBuffer from the Blob URL
    fetch(audioUrl)
      .then((response) => response.arrayBuffer())
      .then((audioData) => {
        // Decode the audio data
        audioContextRef.current.decodeAudioData(audioData, (buffer) => {
          const source = audioContextRef.current.createBufferSource();
          source.buffer = buffer;
          source.connect(audioContextRef.current.destination);
          source.start(0);

          // When the audio finishes, remove the current item from the queue and play the next one
          source.onended = () => {
            audioQueueRef.current.shift(); // Remove the current audio from the queue
            playNextInQueue(); // Play the next audio in the queue
          };
        });
      })
      .catch((error) => {
        console.error("Error fetching or decoding audio:", error);
      });
  };

  // Capture audio from microphone and send it to WebSocket
  // start recording
  const startRecording = async () => {
    setRecording(true);
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("Browser does not support audio recording.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });

        // Send the Blob via WebSocket
        if (websocket.current.readyState === WebSocket.OPEN) {
          websocket.current.send(await audioBlob.arrayBuffer());
          console.log("Sent audio file to backend");
        }

        // Cleanup
      };

      mediaRecorderRef.current.start();
      console.log("Recording started");
    } catch (error) {
      console.error("Error accessing microphone", error);
    }
  };

  // Stop recording
  const stopRecording = () => {
    setRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      console.log("Recording stopped");
    }
  };

  // Handle WebSocket connection events
  const handleClosing = (event) => {
    setIsOnline(false);
    console.log("WebSocket closed:", event);
  };

  const handleConnection = (event) => {
    if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
      setIsOnline(true);
      console.log("WebSocket already connected");
      return; // Return if already connected
    }
    setIsOnline(true);
    console.log("WebSocket connected:", event);
  };

  const handleError = (event) => {
    console.error("WebSocket error:", event);
  };

  // Connect to the WebSocket server
  const connect = () => {
    if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
      console.log("WebSocket already connected");
      return; // Return if already connected
    }

    console.log(process.env.REACT_APP_SOCKET);
    console.log("calling connect to websocket");
    console.log(bot_info.key);
    console.log(call_id);

    websocket.current = new WebSocket(
      process.env.REACT_APP_SOCKET + `/ws/call/${bot_info.key}/${call_id}/`
    );
    websocket.current.onmessage = handleIncoming;
    websocket.current.onclose = handleClosing;
    websocket.current.onopen = handleConnection;
    websocket.current.onerror = handleError;
  };

  useEffect(() => {
    // The signaling  iframe
    let message = { type: "show", source: "sawtaibot" };
    window.parent.postMessage(message, "*");

    // Initialize AudioContext
    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();

    connect();

    return () => {
      if (websocket.current.readyState === WebSocket.OPEN) {
        console.log("closing websocket connection");
        websocket.current.close();
      }

      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  return (
    <>
      <button
        onClick={() => {
          toggleShow(false);
        }}
        className="fixed bottom-4 right-4 inline-flex items-center justify-center w-16 h-16 rounded-full bg-[#2E3349] hover:bg-gray-700 text-sm font-medium text-white disabled:pointer-events-none disabled:opacity-50 border-gray-200 p-0 leading-5 cursor-pointer"
        type="button"
        aria-haspopup="dialog"
        aria-expanded="false"
        data-state="closed"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="size-6"
        >
          <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
          <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
        </svg>
      </button>

      <div
        style={{
          boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)",
        }}
        className="fixed bottom-[calc(4rem+1.5rem)] right-0 mr-4 bg-[#2E3349] rounded-lg border border-[#e5e7eb] w-[320px] h-[400px] flex flex-col"
      >
        <div className="px-6 py-3 bg-[#282D41] rounded-lg">
          <div className="flex flex-col space-y-1.5  ">
            <h2 className="font-semibold text-lg text-white tracking-tight">
              {bot_info.name}
            </h2>
            <p className="text-sm text-white leading-3 ">
              Powered by <strong className="text-[#645DCC]">SAWT AI</strong>
              <div
                className={`w-[10px] h-[10px] rounded-full mt-2 animate-ping   ${
                  isOnline ? "bg-[#645DCC]" : "bg-red-500"
                }`}
              ></div>
            </p>
          </div>
        </div>

        <div className="px-6 basis-3/4 flex flex-col">
          <div className="flex flex-col items-center justify-center basis-3/4 text-white">
            <img
              src={bot_info.image}
              className="rounded-full w-[100px] h-[100px]"
            />

            <div>Tap Record</div>
          </div>

          <div className="flex items-center justify-between pt-0 basis-1/4">
            <button
              onClick={() => {
                toggleShow(false);
              }}
              className="inline-flex items-center justify-center rounded-lg text-sm font-large text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 border-solid border-[#5F5B5B] bg-[#1f2937] hover:bg-[#111827E6] p-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="red"
                className="size-5"
              >
                <path
                  fillRule="evenodd"
                  d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="font-large">End</p>
            </button>

            <button
              onClick={recording ? stopRecording : startRecording}
              className={`inline-flex items-center justify-center rounded-lg text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 p-4 ${
                recording
                  ? "bg-neutral-600 hover:bg-[#111827E6]"
                  : "bg-[#1f2937] hover:bg-[#111827E6]"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-5"
              >
                {recording ? (
                  <>
                    <path
                      fillRule="evenodd"
                      d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z"
                      clipRule="evenodd"
                    />
                  </>
                ) : (
                  <>
                    <path d="M8.25 4.5a3.75 3.75 0 1 1 7.5 0v8.25a3.75 3.75 0 1 1-7.5 0V4.5Z" />
                    <path d="M6 10.5a.75.75 0 0 1 .75.75v1.5a5.25 5.25 0 1 0 10.5 0v-1.5a.75.75 0 0 1 1.5 0v1.5a6.751 6.751 0 0 1-6 6.709v2.291h3a.75.75 0 0 1 0 1.5h-7.5a.75.75 0 0 1 0-1.5h3v-2.291a6.751 6.751 0 0 1-6-6.709v-1.5A.75.75 0 0 1 6 10.5Z" />
                  </>
                )}
              </svg>
              <p>{recording ? "Pause" : "Record"}</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
