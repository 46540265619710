import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Bot from "./bot";

function Button({ toggleShow }) {
  useEffect(() => {
    let message = { type: "hide", source: "sawtaibot" };
    window.parent.postMessage(message, "*");
  }, []);

  return (
    <>
      <button
        onClick={() => {
          toggleShow(true);
        }}
        className="fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-16 h-16  bg-[#2E3349] hover:bg-gray-700 text-sm font-medium text-white m-0 cursor-pointer border-gray-200  p-0 normal-case leading-5 hover:text-gray-900"
        type="button"
        aria-haspopup="dialog"
        aria-expanded="false"
        data-state="closed"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="size-6"
        >
          <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 0 0-1.032-.211 50.89 50.89 0 0 0-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 0 0 2.433 3.984L7.28 21.53A.75.75 0 0 1 6 21v-4.03a48.527 48.527 0 0 1-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979Z" />
          <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 0 0 1.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0 0 15.75 7.5Z" />
        </svg>
      </button>
    </>
  );
}

export default function ButtonPage() {
  const [show, toggleShow] = useState(false);
  const [call_id, setCallID] = useState("");
  
  const bot_id  = window.location.href.split('?')[1].split('=')[1]
  const [bot_info, setBotInfo] = useState(null);

  
  console.log(bot_id)

  useEffect(() => {
    getChatbotData();
  }, [])

  const getChatbotData = () => {
    console.log(process.env.REACT_APP_BACKEND)
    fetch(process.env.REACT_APP_BACKEND + "/api/client/call/id/").then(
      (response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            console.log(`the call id is ${data.id}`)
            setCallID(data.id);
          });
        }
      }
    );

    fetch(
      process.env.REACT_APP_BACKEND + `/api/client/call/${bot_id}/`
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          console.log(data);
          setBotInfo(data);
        });
      }
    });
  };

  if (call_id && bot_info) {
    console.log("bot displayed");

    if (show) {
      
      return <Bot bot_info={bot_info} call_id={call_id} toggleShow={toggleShow} />;
    } else {
      return <Button toggleShow={toggleShow} />;
    }
  } else {
    console.log("bot not showing ...");
    return <></>;
  }
}
